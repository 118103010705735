.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a, a:visited, a:link {
  color: #fff;
  text-decoration: none;
}

.table-recettes {
  border-collapse:separate; 
  border-spacing: 0 0.5em;
}
.ligne-recette {
  background-color: #f8f9fa;
  
  cursor: pointer;
}
.ligne-recette td {
  margin: 0px;
  padding-left: 5px;
}
.ligne-recette:hover {
  background-color: #ddddff;
}

.photo-une {
  width: 130px; height: 80px; position:relative; display: table-cell; vertical-align: middle; background-color:#fff; border: 1px solid #aaa;
}
.photo-une-deux {
  margin:5px;  width:120px;height:100%; background-position:center; background-size: cover;
}

.rubrique {
  font-weight: bold;
  font-size: 120%;
  color: #000000;
  margin-bottom: 50px;
}
.recette-texte {
  font-size: 16px;
  margin-left: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /*min-height: 100vh;*/
  min-height: 10vh;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
